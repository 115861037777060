<template>
	<div class="d-flex align-center justify-center" style="height: 100vh">
		<div class="d-flex flex-column align-center">
			<h2
				class="font-weight-semibold text--primary d-flex align-center justify-center"
			>
				<span class="me-2">Page Not Found</span>
				<v-icon color="warning">mdi-alert</v-icon>
			</h2>
			<p class="text-sm">페이지를 찾을 수 없습니다</p>

			<div class="d-flex justify-center">
				<v-img max-width="120" src="@/assets/images/avatars/12.png" />
			</div>

			<v-btn color="primary" to="/" class="mb-4">메인으로 가기</v-btn>
		</div>
	</div>
</template>

<script>
export default {}
</script>
